import "./HeroImg2Styles.css";
 import React, { Component } from 'react'
 
 class HeroImg2 extends Component {
  render() {
   return (
     <div className="hero-img">
      
        <h4 className="headin">Our Mission: Your Optimal Health and Recovery</h4>
        </div>
       
   );
 }}
 
 export default HeroImg2
 